export const PageLocation = Object.freeze({
  APPLICATIONS: 'APPLICATIONS',
  SCHOOL_MANAGER: 'SCHOOL_MANAGER',
  REGIONAL_COORDINATOR: 'REGIONAL_COORDINATOR',
  TEACHER: 'TEACHER',
  CLASSROOM: 'CLASSROOM',
  EXAMINEE: 'EXAMINEE',
  PROGRAM: 'PROGRAM',
  NONE: 'NONE',
})
